import request from "@/utils/request";

export async function reportPage(data) {
  try {
    const res = await request({
      url: "/api/FrontReport/ReportPage",
      method: "post",
      data
    });
    return res.response;
  } catch (e) {
    return null;
  }
}
